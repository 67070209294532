<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p(v-html="exp.expDesc")
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "CompleteProfile",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "3.1 How to Complete Your Profile?",
          description: [
            {
              text: "A complete profile will help you gain more trust from your potential customers who visit your website for the first time. There are three sections that need to be complete : profile picture, banner image and profile details.",
              imgType: false,
            },
          ],
          explanation: [
            {
              expTitle: "Add / change the profile picture to you own:",
              expDesc: "1. Click on edit icon of profile picture",
              imgType: true,
              img: "200.Website profile – empty profile – 3.jpg",
            },
            {
              expTitle: "",
              expDesc: "2. Select picture and edit the picture if you need to. Click correct mark icon once it complete",
              imgType: true,
              img: "212.Website-edit profile-crop – 1.jpg",
            },
            {
              expTitle: "Add / change the cover image:",
              expDesc: "1. Click on edit icon of cover picture",
              imgType: true,
              img: "200.Website profile – empty profile – 4.jpg",
            },
            {
              expTitle: "",
              expDesc: "2. Select “Add new photo”",
              imgType: true,
              img: "220.Website-edit banner – 2.jpg",
            },
            {
              expTitle: "",
              expDesc: "3. Select picture and edit the picture if you need to. Click correct mark icon",
              imgType: true,
              img: "212.Website-edit profile-crop – 2.jpg",
            },
            {
              expTitle: "",
              expDesc: "4. Click “Save” once it complete",
              imgType: true,
              img: "223.Website slideshow-1 – 1.jpg",
            },
            {
              expTitle: "Add / change the profile details:",
              expDesc: "1. Click the “Manage” at the top of right corner",
              imgType: true,
              img: "200.Website profile – empty profile – 5.jpg",
            },
            {
              expTitle: "",
              expDesc: "2. Click “Manage profile”",
              imgType: true,
              img: "350. Manage website – 1.jpg",
            },
            {
              expTitle: "",
              expDesc: "3. Enter the information of background and click “Next” <p class='ps-3'>a) Display name <br/>b) Job Title <br/>c) Company <br/>d) Additional Info (Optional) <br/>e) About me (short intro of company/ product/ services)</p>",
              imgType: true,
              img: "manage-profike.jpg",
            },
            {
              expTitle: "",
              expDesc: "4. Enter the information of contact and click “Next”  <p class='ps-3'>a) WhatsApp number <br/>b) Email</p>",
              imgType: true,
              img: "242. Manage profile-2.jpg",
            },
            {
              expTitle: "",
              expDesc: "5. Enter the Hashtag (click done on Keyboard to capture the word) for your Specialty (optional) and click “Done” once complete",
              imgType: true,
              img: "245. Manage profile-add tag-3.jpg",
            },
          ],
        },
      ],
    };
  },
};
</script>
